<template>
    <Toast :baseZIndex="9999" />
    <!-- Add/Edit wTemplate starts here -->
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>{{ this.dialogHeader }} <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan"
                        v-if="totalRecords.length != 0">{{ this.shorttemplateName.label }}
                    </Chip>
                </h5>
                <div>
                    <Button label="Listing" class="p-button-sm p-mr-2" @click="goToListing" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left"
                        v-if="count != 0 && totalRecords.length != 0" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" v-if="count < totalRecords.length - 1 && totalRecords.length != 0" />
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="chatbotId">
                        ChatbotId
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="chatbotId" v-model="chatbotId" :options="chatbotList" optionLabel="label"
                        placeholder="Select ..." appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.chatbotId.$error">{{
                        v$.chatbotId.$errors[0].$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="language">
                        Language
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="language" v-model="language" :options="languageList" optionLabel="label"
                        placeholder="Select ..." appendTo="body" @change="isAlreadyPresentWebBotTemplateName($event)">
                    </Dropdown>
                    <small class="p-invalid p-error" v-if="v$.language.$error">{{
                        v$.language.$errors[0].$message
                        }}</small>
                </div>
                <!-- <div class="p-field p-col-12 p-md-4">
                    <label for="templateName">
                        Template Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="templateName" v-model.trim="templateName"
                        @input="isAlreadyPresentWebBotTemplateName($event)" required="true" maxlength="50" autofocus
                        :class="{ 'p-invalid': submitted && !templateName }" />
                    <small class="p-invalid p-error" v-if="v$.templateName.$error">{{
                        v$.templateName.$errors[0].$message
                        }}</small>
                    <small class="p-invalid p-error" v-if="foundUniqueIdErrorMsg">{{ foundUniqueIdErrorMsg }}</small>

                </div> -->
                <div class="p-field p-col-12 p-md-4">
                    <label for="shorttemplateName">
                        Template For
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="shorttemplateName" v-model="shorttemplateName" :options="shorttemplateNameList"
                        optionLabel="label" placeholder="Select ..." appendTo="body"
                        @change="isAlreadyPresentWebBotTemplateName($event)"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.shorttemplateName.$error">{{
                        v$.shorttemplateName.$errors[0].$message
                        }}</small>
                    <small class="p-invalid p-error" v-if="foundUniqueIdErrorMsg">{{ foundUniqueIdErrorMsg }}</small>

                </div>
                <!-- <div class="p-field p-col-12 p-md-4">
                    <label for="vendors_template_id">
                        Vendor Template Id
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="vendors_template_id" v-model.trim="vendors_template_id" maxlength="20" />
                    <small class="p-invalid p-error" v-if="v$.vendors_template_id.$error">{{
        v$.vendors_template_id.$errors[0].$message
    }}</small>
                </div> -->
                <div class="p-field p-col-12 p-md-6">
                    <label for="templateBody">
                        Template Body
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Textarea id="templateBody" v-model="templateBody" :autoResize="true" rows="2" cols="30"
                        placeholder="enter template body" autofocus
                        :class="{ 'p-invalid': submitted && !templateBody }" />
                    <small class="p-invalid p-error" v-if="v$.templateBody.$error">{{
                        v$.templateBody.$errors[0].$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="template_variables">
                        Template Variables
                    </label>
                    <Textarea id="template_variables" :autoResize="true" rows="2" cols="30"
                        v-model.trim="template_variables" required="true" placeholder="enter template variables"
                        autofocus />
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="template_reply_type">
                        Reply Type
                    </label>
                    <Dropdown id="template_reply_type" v-model="template_reply_type" :options="templatereplytypeList"
                        optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                </div>
                <div class="p-field p-col-12 p-md-8">
                    <label for="next_template_name">
                        Next Template Name
                    </label>
                    <Dropdown id="next_template_name" showClear filter v-model="next_template_name"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label>Header status <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="headerstatusavl" name="option" v-bind:value="1" v-model="headerstatus"
                            :checked="headerstatus == 1" />
                        <label for="headerstatusavl" class="p-mr-2">Available</label>
                        <RadioButton id="headerstatusnotavl" name="option" v-bind:value="0" v-model="headerstatus"
                            :checked="headerstatus == 0" />
                        <label for="headerstatusnotavl" class="p-mr-2">Not Available</label>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-4" v-if="headerstatus == 1">
                    <label for="header_file_type">
                        Header File Type <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="header_file_type" showClear v-model="header_file_type" :options="headerFileTypeList"
                        optionLabel="label" placeholder="Select ..." @change="clearuploadimage()" appendTo="body">
                    </Dropdown>
                    <div v-if="headerstatus == 1">
                        <small class="p-invalid p-error" v-if="v$.header_file_type.$error">{{
                            v$.header_file_type.$errors[0].$message
                            }}</small>
                    </div>
                </div>

                <div class="p-field p-col-12 p-md-4" v-if="headerstatus == 1 && header_file_type">
                    <label>Upload Header <span class="p-invalid p-error">*</span></label>
                    <div v-if="header_file_type.value == 1">
                        <FileUpload v-model="header_file_url" mode="basic" ref="header_file_url" :maxFileSize="1000000"
                            accept="image/*" chooseLabel="Upload Header Image" auto @select="onSelectProfile">
                        </FileUpload>
                        <span v-if="header_file_url_edit && profileUrl == null">
                            <img class="p-pt-2" role="presentation" alt="edit" :src="header_file_url_edit" width="120"
                                height="120" />
                        </span>
                    </div>
                    <div v-if="header_file_type.value == 2">
                        <FileUpload mode="basic" ref="docuploadref" class="custom-upload-btn-ultima" accept="pdf/*"
                            v-model="header_file_url" chooseLabel="Upload Header Document"
                            v-on:change="onSelectProfile()" />
                    </div>
                    <div v-if="header_file_type.value == 3">
                        <FileUpload mode="basic" ref="videouploadref" class="custom-upload-btn-ultima" accept="mp4/*"
                            v-model="header_file_url" chooseLabel="Upload Header Video"
                            v-on:change="onSelectProfile()" />
                    </div>
                    <div v-if="header_file_type.value == 4">
                        <FileUpload v-model="header_file_url" mode="basic" ref="tuneuploadeditref"
                            :maxFileSize="1000000" accept="mp3/*" chooseLabel="Upload Header Tune" auto
                            @select="onSelectProfile" />
                    </div>
                    <div v-if="header_file_type.value != 1">
                        <a v-if="header_file_url_edit != '' && header_file_url_edit != null" class="view-btn"
                            target="_blank" :href="header_file_url_edit">Preview</a>
                    </div>
                    <small class="p-invalid p-error" v-if="profileImageError">{{ profileImageError }}</small>
                    <div v-if="headerstatus == 1 && header_file_type && profileImageError == ''">
                        <small class="p-invalid p-error" v-if="v$.header_file_url.$error">{{
                            v$.header_file_url.$errors[0].$message
                            }}</small>
                    </div>
                    <div>
                        <div v-if="header_file_type.value == 1">
                            <img v-if="profileUrl" class="p-pt-2" role="presentation" :alt="profileUrl.name"
                                :src="profileUrl.url" width="120" height="120" />
                        </div>
                        <div v-if="header_file_type.value != 1">
                            <div class="p-field p-col-12 p-md-12" v-if="profileUrl != null">{{
                                profileUrl.name
                                }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="first_button_name">
                        First Button Name
                    </label>
                    <InputText id="first_button_name" v-model.trim="first_button_name" required="true" maxlength="50" />
                </div>
                <div class="p-field p-col-12 p-md-8" v-if="first_button_name">
                    <label for="first_button_template_name">
                        First Button Template Name

                        <span class="p-invalid p-error" v-if="first_button_name != ''">*</span>
                    </label>
                    <Dropdown id="first_button_template_name" showClear filter v-model="first_button_template_name"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                    <div v-if="first_button_name != ''">
                        <small class="p-invalid p-error" v-if="v$.first_button_template_name.$error">{{
                            v$.first_button_template_name.$errors[0].$message
                            }}</small>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="second_button_name">
                        Second Button Name
                    </label>
                    <InputText id="second_button_name" v-model.trim="second_button_name" maxlength="50" />
                </div>

                <div class="p-field p-col-12 p-md-8" v-if="second_button_name">
                    <label for="second_button_template_name">
                        Second Button Template Name
                        <span class="p-invalid p-error" v-if="second_button_name != ''">*</span>

                    </label>
                    <Dropdown id="second_button_template_name" showClear filter v-model="second_button_template_name"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                    <div v-if="second_button_name != ''">
                        <small class="p-invalid p-error" v-if="v$.second_button_template_name.$error">{{
                            v$.second_button_template_name.$errors[0].$message
                            }}</small>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="third_button_name">
                        Third Button Name
                    </label>
                    <InputText id="third_button_name" v-model.trim="third_button_name" maxlength="50" />
                </div>
                <div class="p-field p-col-12 p-md-8" v-if="third_button_name">
                    <label for="third_button_template_name">
                        Third Button Template Name
                        <span class="p-invalid p-error" v-if="third_button_name != ''">*</span>
                    </label>
                    <Dropdown id="third_button_template_name" showClear filter v-model="third_button_template_name"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                    <div v-if="third_button_name != ''">
                        <small class="p-invalid p-error" v-if="v$.third_button_template_name.$error">{{
                            v$.third_button_template_name.$errors[0].$message
                            }}</small>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="fourth_button_name">
                        Fourth Button Name
                    </label>
                    <InputText id="fourth_button_name" class="p-text-capitalize" v-model.trim="fourth_button_name"
                        required="true" maxlength="50" />
                </div>
                <div class="p-field p-col-12 p-md-8" v-if="fourth_button_name">
                    <label for="fourth_button_template_name">
                        Fourth Button Template Name
                        <span class="p-invalid p-error" v-if="fourth_button_name != ''">*</span>
                    </label>
                    <Dropdown id="fourth_button_template_name" showClear filter v-model="fourth_button_template_name"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                    <div v-if="fourth_button_name != ''">
                        <small class="p-invalid p-error" v-if="v$.fourth_button_template_name.$error">{{
                            v$.fourth_button_template_name.$errors[0].$message
                            }}</small>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="fifth_button_name">
                        Fifth Button Name
                    </label>
                    <InputText id="fifth_button_name" class="p-text-capitalize" v-model.trim="fifth_button_name"
                        maxlength="50" />
                </div>

                <div class="p-field p-col-12 p-md-8" v-if="fifth_button_name">
                    <label for="fifth_button_template_name">
                        Fifth Button Template Name
                        <span class="p-invalid p-error" v-if="fifth_button_name != ''">*</span>
                    </label>
                    <Dropdown id="fifth_button_template_name" showClear filter v-model="fifth_button_template_name"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                    <div v-if="fifth_button_name != ''">
                        <small class="p-invalid p-error" v-if="v$.fifth_button_template_name.$error">{{
                            v$.fifth_button_template_name.$errors[0].$message
                        }}</small>
                    </div>
                </div>
            </div>
        </div>
        <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
            class="p-button-text p-ml-auto" @click="addNewWebBotTemplate" style="width: 90px;"
            :disabled="showLoader"></Button>
        <Divider type="dashed" />
        <!-- Template variables starts here -->
        <div>
            <span>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#shortname#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#name#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#dateofbirth#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#address#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#pincode#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#votersname#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#voternumber#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#assemblyconstituency#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#assemblyconstituencynumber#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#pollingstation#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#partnumber#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#area#]</Chip>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan">[#parliamentaryconstituency#]</Chip>
            </span>
        </div>
        <!-- Template variables ends here -->
    </div>
    <!-- Add/Edit wTemplate ends here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import { required, helpers, requiredIf } from '@vuelidate/validators';
import { inject } from 'vue';

export default {
    inject: ['global'],
    props: {
        propsData: {
            required: false,
        },
        propsData1: {
            required: true,
        },
        propsData2: {
            required: true,
        },
        propsData3: {
            required: true,
        },
        propsData4: {
            required: true,
        },
        propsData5: {
            required: true,
        },
    },
    emits: {
        go_to_previous: null,
    },
    data() {
        const globalData = inject('global');
        return {
            templateEventList: [],
            v$: useValidate(),
            isDialogOpen: false,
            showLoader: false,
            expandedRows: [],
            loading: false,
            page_no: 0,
            totalRecords: [],
            isEditwTemplate: false,
            fromDate: null,
            toDate: null,
            todaysDate: new Date(),
            dialogHeader: "Add Web Bot Template",
            validation: {},
            profileImageError: '',

            languageList: [],
            shorttemplateNameList: [],
            templatereplytypeList: globalData.state.templateReplyTypeList,

            chatbotList: [],
            chatbot: '',
            submitted: false,
            // templateName: '',
            templateAction: '',
            templateEvent: '',
            templateBody: '',
            template_variables: '',
            language: '',
            template_reply_type: '',
            next_template_name: '',

            first_button_name: '',
            first_button_template_name: '',
            second_button_name: '',
            second_button_template_name: '',
            third_button_name: '',
            third_button_template_name: '',
            fourth_button_name: '',
            fourth_button_template_name: '',
            fifth_button_name: '',
            fifth_button_template_name: '',
            // vendors_template_id: '',
            header_file_type: '',
            headerFileTypeList: globalData.state.headerFileTypeList,
            shorttemplateName: '',
            headerstatus: 0,
            header_file_url: null,
            header_file_url_edit: null,
            chatbotId: '',
            profileUrl: null,
            allowedExtensions: '',
            docuploadref: '',
            videouploadref: '',
            tuneuploadeditref: '',
            file: '',
            row_id: 0,
            foundUniqueIdErrorMsg: '',

            isCopyWhatsappSetting: false,
            selectedProduct: null,
            copysettingloader: false,
            clientList: [],
            selectedWhatsAppSettings: [],
            client_name: '',
            localAdminName: '',
            count: 0,
        };
    },
    validations() {
        return {
            chatbotId: { required: helpers.withMessage('Please select chatbotId', required) },
            language: { required: helpers.withMessage('Please select language', required) },
            // templateName: { required: helpers.withMessage('Please enter template name', required) },
            shorttemplateName: { required: helpers.withMessage('Please enter short template name', required) },
            templateBody: { required: helpers.withMessage('Please enter template body', required) },
            // vendors_template_id: { required: helpers.withMessage('Please enter vendor template Id', required) },
            headerstatus: { required: helpers.withMessage('Please enter header status', required) },
            header_file_type: {
                requiredIf: helpers.withMessage("Please select header file type",
                    requiredIf(
                        this.headerstatus == 1
                    )
                ),
            },
            header_file_url: {
                requiredIf: helpers.withMessage("Please upload header file",
                    requiredIf(
                        this.headerstatus == 1 && (this.header_file_url_edit == null || this.header_file_url_edit == '')
                    )
                ),
            },
            first_button_template_name: {
                requiredIf: helpers.withMessage("Please select First button template",
                    requiredIf(
                        this.first_button_name != '' && this.first_button_name != null
                    )
                ),
            },
            second_button_template_name: {
                requiredIf: helpers.withMessage("Please select Second button template",
                    requiredIf(
                        this.second_button_name != '' && this.second_button_name != null
                    )
                ),
            },
            third_button_template_name: {
                requiredIf: helpers.withMessage("Please select Third button template",
                    requiredIf(
                        this.third_button_name != '' && this.third_button_name != null
                    )
                ),
            },
            fourth_button_template_name: {
                requiredIf: helpers.withMessage("Please select Fourth button template",
                    requiredIf(
                        this.fourth_button_name != '' && this.fifth_button_name != null
                    )
                ),
            },
            fifth_button_template_name: {
                requiredIf: helpers.withMessage("Please select Fifth button template",
                    requiredIf(
                        this.fifth_button_name != '' && this.fifth_button_name != null
                    )
                ),
            },
        };
    },

    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.count = this.propsData5;
        this.totalRecords = this.propsData4;
        this.shorttemplateNameList = this.propsData1;
        this.chatbotList = this.propsData2;
        this.languageList = this.propsData3;
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.localAdminName = localStorage.localAdminName;
        this.loading = true;
        if (this.propsData != null) {
            this.onEditWebBotTemplateSettings(this.propsData);
        } else {
            this.getAllWebBotTemplateOnly();
            this.onAddWebBotTemplateSettings();
            this.totalRecords = []
        }
    },
    methods: {
        goToListing() {
            this.$emit('go_to_previous');
        },
        goToNext() {
            this.count++
            this.getRecordById(this.totalRecords[this.count]['tg1']);
        },
        goToPrevious() {
            this.count--
            this.getRecordById(this.totalRecords[this.count]['tg1']);
        },
        getRecordById(id) {
            console.log(this.totalRecords)
            let tg1 = id;
            let vals = this.totalRecords.filter(function (item) {
                return item.tg1 == tg1;
            });
            if (vals.length > 0) {
                this.onEditWebBotTemplateSettings(vals[0]);
            }
        },
        getAllWebBotTemplateOnly() {
            this.loading = true;
            this.ApiService.getAllWebBotTemplateOnly({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    var templateEventList = data.data;
                    let arrConverted = templateEventList;
                    for (let i = 0; i < arrConverted.length; i++) {
                        let vals = this.languageList.filter(function (item) {
                            return item.value == arrConverted[i]['tg6'];
                        });
                        if (vals.length > 0) {
                            let newElement = vals[0]['label'];
                            if (templateEventList[i]['label'] != null) {
                                this.templateEventList.push({
                                    'value': templateEventList[i]['value'],
                                    'label': templateEventList[i]['label'] + "(" + newElement + ")",
                                    'tg3': templateEventList[i]['tg3'],
                                    'tg6': templateEventList[i]['tg6'],
                                    'tg24': templateEventList[i]['tg24'],
                                });
                            }
                        }
                    }
                } else {
                    this.templateEventList = '';
                }
                this.loading = false;
            });
        },
        clearuploadimage() {
            this.profileImageError = '';
            this.header_file_url = null;
            this.profileUrl = '';
            this.header_file_url_edit = null;
        },
        isAlreadyPresentWebBotTemplateName() {
            if (this.shorttemplateName != '') {
                this.ApiService.isAlreadyPresentWebBotTemplateName({ uniqueNameId: this.shorttemplateName.value, uniqueName: this.shorttemplateName.label, rowId: this.row_id, clientId: this.routeParam, languageId: this.language.value, laguageName: this.language.label }).then((data) => {
                    if (data.status == 200) {
                        this.foundUniqueIdErrorMsg = '';
                        if (data.count > 0)
                            this.foundUniqueIdErrorMsg = "This template name already exist";
                        this.loading = false;
                    } else {
                        this.foundUniqueIdErrorMsg = '';
                        this.loading = false;
                    }
                    this.loading = false;
                });
            }
        },

        async onSelectProfile(e) {
            if (this.header_file_type.value == 1) {
                this.allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                this.file = e.files[0];
            } else if (this.header_file_type.value == 2) {
                this.allowedExtensions = /(\.pdf)$/i;
                this.file = this.$refs.docuploadref.files[0];
            } else if (this.header_file_type.value == 3) {
                this.allowedExtensions = /(\.mp4|\.mpeg)$/i;
                this.file = this.$refs.videouploadref.files[0];
            } else if (this.header_file_type.value == 4) {
                this.allowedExtensions = /(\.mp3)$/i;
                this.file = this.$refs.tuneuploadeditref.files[0];
            }

            if (!this.file.size > 2048) {
                this.profileImageError = 'File size cannot exceed more than 2MB';
                this.header_file_url = '';
                this.profileUrl = '';
            } else if (!this.file.name.match(this.allowedExtensions)) {
                this.profileImageError = 'Invalid file type';
                this.header_file_url = '';
                this.profileUrl = '';
            } else {
                this.profileImageError = '';
                if (this.header_file_type.value == 1) {
                    this.header_file_url = e.files[0];
                    this.profileUrl = { url: this.file.objectURL, name: this.file.name };
                }
                else {
                    this.profileUrl = { name: this.file.name };
                    this.header_file_url = this.file;
                }
            }
        },

        onAddWebBotTemplateSettings() {
            this.templateName = '';
            this.templateAction = '';
            this.templateEvent = '';
            this.templateBody = '';
            this.template_variables = '';
            this.language = '';
            this.template_reply_type = '';
            this.next_template_name = '';

            this.first_button_name = '';
            this.first_button_template_name = '';
            this.second_button_name = '';
            this.second_button_template_name = '';
            this.third_button_name = '';
            this.third_button_template_name = '';
            this.fourth_button_name = '';
            this.fourth_button_template_name = '';
            this.fifth_button_name = '';
            this.fifth_button_template_name = '';
            // this.vendors_template_id = '';
            this.header_file_type = '';
            this.shorttemplateName = '';
            this.headerstatus = 0;
            this.header_file_url = null;
            this.header_file_url_edit = null;
            this.chatbotId = '';
            this.profileUrl = null;
            this.submitted = false;
        },

        onEditWebBotTemplateSettings(e) {
            this.row_id = e.tg1;
            this.templateName = '';
            this.templateAction = '';
            this.templateEvent = '';
            this.templateBody = '';
            this.template_variables = '';
            this.language = '';
            this.template_reply_type = '';
            this.next_template_name = '';

            this.first_button_name = '';
            this.first_button_template_name = '';
            this.second_button_name = '';
            this.second_button_template_name = '';
            this.third_button_name = '';
            this.third_button_template_name = '';
            this.fourth_button_name = '';
            this.fourth_button_template_name = '';
            this.fifth_button_name = '';
            this.fifth_button_template_name = '';
            // this.vendors_template_id = '';
            this.header_file_type = '';
            this.shorttemplateName = '';
            this.headerstatus = 0;
            this.header_file_url = null;
            this.header_file_url_edit = null;
            this.chatbotId = '';
            this.profileUrl = null;
            this.submitted = false;
            this.ApiService.getAllWebBotTemplateOnly({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    var templateEventList = data.data;
                    let arrConverted = templateEventList;
                    for (let i = 0; i < arrConverted.length; i++) {
                        let vals = this.languageList.filter(function (item) {
                            return item.value == arrConverted[i]['tg6'];
                        });
                        if (vals.length > 0) {
                            let newElement = vals[0]['label'];
                            if (templateEventList[i]['label'] != null) {
                                this.templateEventList.push({
                                    'value': templateEventList[i]['value'],
                                    'label': templateEventList[i]['label'] + "(" + newElement + ")",
                                    'tg3': templateEventList[i]['tg3'],
                                    'tg6': templateEventList[i]['tg6'],
                                    'tg24': templateEventList[i]['tg24'],
                                });
                            }
                        }
                    }
                    let templateEventnextbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tg8;
                    });
                    if (templateEventnextbtn.length > 0) {
                        this.next_template_name = templateEventnextbtn[0];
                    }

                    let templateEventfirstbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tg10;
                    });
                    if (templateEventfirstbtn.length > 0) {
                        this.first_button_template_name = templateEventfirstbtn[0];
                    }

                    let templateEventSecondbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tg12;
                    });
                    if (templateEventSecondbtn.length > 0) {
                        this.second_button_template_name = templateEventSecondbtn[0];
                    }

                    let templateEventthirdbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tg14;
                    });
                    if (templateEventthirdbtn.length > 0) {
                        this.third_button_template_name = templateEventthirdbtn[0];
                    }
                    let templateEventFourthbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tg19;
                    });
                    if (templateEventFourthbtn.length > 0) {
                        this.fourth_button_template_name = templateEventFourthbtn[0];
                    }

                    let templateEventFifthbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tg21;
                    });
                    if (templateEventFifthbtn.length > 0) {
                        this.fifth_button_template_name = templateEventFifthbtn[0];
                    }
                } else {
                    this.templateEventList = '';
                }
            });
            this.row_id = e.tg1;
            if (e.tg2 !== 0) {
                let chatbotListId = this.chatbotList.filter(function (item) {
                    return item.value == e.tg2;
                });
                if (chatbotListId.length > 0) {
                    this.chatbotId = chatbotListId[0];
                }
            }
            //this.templateName = e.tg3;
            this.templateBody = e.tg4;
            this.template_variables = e.tg5;
            let languageListId = this.languageList.filter(function (item) {
                return item.value == e.tg6;
            });
            if (languageListId.length > 0) {
                this.language = languageListId[0];
            }

            this.shorttemplateName = '';
            if (e.tg25 !== 0) {
                let shorttemplateNameListId = this.shorttemplateNameList.filter(function (item) {
                    return item.value == e.tg25;
                });
                if (shorttemplateNameListId.length > 0) {
                    this.shorttemplateName = shorttemplateNameListId[0];
                }
            }


            let template_reply_type_id = this.templatereplytypeList.filter(function (item) {
                return item.value == e.tg7;
            });
            if (template_reply_type_id.length > 0) {
                this.template_reply_type = template_reply_type_id[0];
            }

            let header_file_type_id = this.headerFileTypeList.filter(function (item) {
                return item.value == e.tg23;
            });
            if (header_file_type_id.length > 0) {
                this.header_file_type = header_file_type_id[0];
            }


            this.next_template_name = e.tg8;

            if (e.tg9 != 'null' && e.tg9 != null)
                this.first_button_name = e.tg9;
            if (e.tg11 != 'null' && e.tg11 != null)
                this.second_button_name = e.tg11;
            if (e.tg13 != 'null' && e.tg13 != null)
                this.third_button_name = e.tg13;
            if (e.tg18 != 'null' && e.tg18 != null)
                this.fourth_button_name = e.tg18;
            if (e.tg20 != 'null' && e.tg20 != null)
                this.fifth_button_name = e.tg20;

            // this.vendors_template_id = e.tg22;
            this.headerstatus = e.tg15;
            if (e.tg16 != null || e.tg16 != '') {
                this.header_file_url_edit = e.tg16;
            }
            this.dialogHeader = "Edit Web Bot Template";
            this.isDialogOpen = true;
            this.submitted = false;
        },

        async addNewWebBotTemplate() {
            this.submitted = true;
            this.v$.$validate();
            let formData = new FormData();
            formData.append("tg1", this.row_id);
            formData.append("tg2", this.chatbotId.value);
            formData.append('tg4', this.templateBody);
            formData.append('tg5', this.template_variables);
            formData.append('tg6', this.language.value);
            if (this.template_reply_type.value) {
                formData.append('tg7', this.template_reply_type.value);
            } else {
                formData.append('tg7', '');
            }
            if (this.next_template_name) {
                formData.append('tg8', this.next_template_name.value);
            } else {
                formData.append('tg8', '');
            }
            formData.append('tg9', this.first_button_name);
            if (this.first_button_template_name !== '' && this.first_button_template_name !== null) {
                formData.append('tg10', this.first_button_template_name.value);
            } else {
                formData.append('tg10', '');
            }
            formData.append('tg11', this.second_button_name);
            if (this.second_button_template_name !== '' && this.second_button_template_name !== null) {
                formData.append('tg12', this.second_button_template_name.value);
            } else {
                formData.append('tg12', '');
            }
            formData.append('tg13', this.third_button_name);
            if (this.third_button_template_name !== '' && this.third_button_template_name !== null) {
                formData.append('tg14', this.third_button_template_name.value);
            }
            else {
                formData.append('tg14', '');
            }
            formData.append('tg15', this.headerstatus);
            if (this.headerstatus == 1) {
                if (this.header_file_url != null) {
                    formData.append('tg16', this.header_file_url);
                    formData.append('tg23', this.header_file_type.value);
                }
            } else {
                formData.append('tg16', '');

                formData.append('tg23', 0);
            }
            formData.append('tg18', this.fourth_button_name);
            if (this.fourth_button_template_name !== '') {
                formData.append('tg19', this.fourth_button_template_name.value);
            }
            formData.append('tg20', this.fifth_button_name);
            if (this.fifth_button_template_name !== '') {
                formData.append('tg21', this.fifth_button_template_name.value);
            }
            formData.append('tg24', this.shorttemplateName.label);
            formData.append('tg25', this.shorttemplateName.value);
            formData.append('clientId', this.routeParam);
            if (!this.v$.$error && this.foundUniqueIdErrorMsg == '') {
                this.showLoader = true;
                let result = await this.ApiService.addUpdateClientWebBotTemplates(formData)
                this.showLoader = false;
                if (result.success) {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: result.message, life: 3000 });
                    this.wTemplateId = '';
                    this.isDialogOpen = false;
                    setTimeout(() => {
                        this.v$.$reset();
                    }, 0);
                    if (this.row_id == 0) {
                        this.goToListing();
                    } else {
                        const element = this.totalRecords.find(e => e.tg1 === this.row_id);
                        if (element) {
                            element.tg2 = this.chatbotId.value;
                            element.tg4 = this.templateBody;
                            element.tg5 = this.template_variables;
                            element.tg6 = this.language.value;
                            if (this.template_reply_type.value) {
                                element.tg7 = this.template_reply_type.value;
                            } else {
                                element.tg7 = '';
                            }
                            if (this.next_template_name) {
                                element.tg8 = this.next_template_name.value;
                            } else {
                                element.tg8 = '';
                            }
                            element.tg9 = this.first_button_name;
                            if (this.first_button_template_name !== '' && this.first_button_template_name !== null) {
                                element.tg10 = this.first_button_template_name.value;
                            } else {
                                element.tg10 = '';
                            }
                            element.tg11 = this.second_button_name;
                            if (this.second_button_template_name !== '' && this.second_button_template_name !== null) {
                                element.tg12 = this.second_button_template_name.value;
                            } else {
                                element.tg12 = '';
                            }
                            element.tg13 = this.third_button_name;
                            if (this.third_button_template_name !== '' && this.third_button_template_name !== null) {
                                element.tg14 = this.third_button_template_name.value;
                            }
                            else {
                                element.tg14 = '';
                            }
                            element.tg15 = this.headerstatus;
                            if (this.headerstatus == 1) {
                                if (this.header_file_url != null) {
                                    element.tg16 = this.header_file_url;
                                    element.tg23 = this.header_file_type.value;
                                }
                            } else {
                                element.tg16 = '';
                                element.tg23 = 0;
                            }
                            element.tg18 = this.fourth_button_name;
                            if (this.fourth_button_template_name !== '') {
                                element.tg19 = this.fourth_button_template_name.value;
                            }
                            element.tg20 = this.fifth_button_name;
                            if (this.fifth_button_template_name !== '') {
                                element.tg21 = this.fifth_button_template_name.value;
                            }
                            element.tg24 = this.shorttemplateName.label;
                            element.tg25 = this.shorttemplateName.value;
                        }
                    }
                } else {
                    this.isDialogOpen = false;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Web Bot Template not added. Please try again', life: 3000 });
                }
            }
        },
    }

};
</script>

<style scoped></style>
